<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'FishingLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.fishing_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.fishing_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.fishing_keyword')}` }]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
